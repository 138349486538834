const ClientsMap = {
  test: {
    name: 'Test Rentals',
    // url: 'http://localhost:9000/'
    // url: 'https://s0nwvs979h.execute-api.us-west-2.amazonaws.com/test/',
    url: 'https://1e5ru2rj20.execute-api.us-west-2.amazonaws.com/test2/'
  },
  demo: {
    name: 'GoLux Rentals',
    url: 'https://glqmgqi5h4.execute-api.us-west-2.amazonaws.com/prod/'
  },
  quality: {
    name: 'Quality Rentalz',
    url: 'https://gzlalegiqj.execute-api.us-west-2.amazonaws.com/prod/'
  },
  dde: {
    name: 'DDE Rentals',
    url: 'https://nbz1rdwbo6.execute-api.us-west-2.amazonaws.com/prod/'
  },
  amd: {
    name: 'AMD Auto Group',
    url: 'https://pk14l34rk8.execute-api.us-west-2.amazonaws.com/prod/'
  },
  fantasy: {
    name: 'Fantasy Exotics',
    url: 'https://12yw54h3th.execute-api.us-west-2.amazonaws.com/prod/'
  },
  777: {
    name: '777 Exotics',
    url: 'https://punnx1809a.execute-api.us-west-2.amazonaws.com/prod/'
  },
  drivela: {
    name: 'DRIVE LA',
    url: 'https://z90ld6ouq9.execute-api.us-west-2.amazonaws.com/prod/'
  },
  drivela2: {
    name: 'DRIVE LA',
    url: 'https://l466fg76ik.execute-api.us-west-2.amazonaws.com/prod/'
  },
  peacock: {
    name: 'Peacock Rentals',
    url: 'https://tjg60t43t5.execute-api.us-west-2.amazonaws.com/prod/'
  },
  'la-exotics': {
    name: 'LA Exotic Rentals',
    url: 'https://95wpap5jb7.execute-api.us-west-2.amazonaws.com/prod/'
  }
}

export default ClientsMap
